import React from "react";
import { Button, CloseButton, Container, Navbar, Offcanvas } from "react-bootstrap"
import { Contents } from "../components/Contents"
import { FSView } from "../components/FSView"
import { styles } from "../components/styles"
import { DirNode, FileNode, Store } from "../service/types";
import { All } from "../utilComponents/All";

export interface IContentsViewProps {
    selectedStore: Store;
    clearSelection: () => void;
    pathStack: string[];
    popPath: () => void;
    items: (FileNode | DirNode)[] | null;
    onItemSelect: (x: (FileNode | DirNode)) => void;
    slicePathStack: (n: number) => void;
}

interface ICanvasProps {
    showCanvas: boolean;
    setShowCanvas: (x: boolean) => void;
}

const FSViewWrapper = ({ showCanvas, setShowCanvas, children }: React.PropsWithChildren<ICanvasProps>) => All([
    c => <div
        className="d-none d-md-block"
        style={{ ...styles.column as any, width: "30%", overflowY: "scroll" }}
    >{c}</div>,
    c => <Offcanvas
        className="d-sm-block d-md-none"
        placement="start"
        show={showCanvas}
    >
        {c}
    </Offcanvas>
])({ children })

const ContentsWrapper = ({ setShowCanvas, children }: React.PropsWithChildren<ICanvasProps>) => All([
    c => <div
        className="d-none d-md-block"
        style={{ ...styles.column as any, width: "70%", overflowY: "scroll" }}
    >{c}</div>,
    c => <div
        className="d-sm-block d-md-none"
        style={{ ...styles.column as any, width: "100%", overflowY: "scroll" }}
    >
        <Navbar>
            <Container>
                <Navbar.Text>
                    <Button onClick={() => setShowCanvas(true)}>Show</Button>
                </Navbar.Text>
            </Container>
        </Navbar>
        {c}
    </div>
])({ children })

export const ContentsView = (props: IContentsViewProps) => {

    const [showCanvas, setShowCanvas] = React.useState<boolean>(false);


    const {
        selectedStore,
        clearSelection,
        pathStack,
        popPath,
        items,
        onItemSelect,
        slicePathStack
    } = props;
    return (
        <>
            <FSViewWrapper showCanvas={showCanvas} setShowCanvas={setShowCanvas}>
                <div className="d-flex flex-row px-2">
                    <h2>{selectedStore.storeName}</h2>
                    <CloseButton
                        style={{ marginLeft: "auto" }}
                        onClick={() => setShowCanvas(false)}
                    />
                </div>
                <FSView
                    pathStack={pathStack}
                    goUp={popPath}
                    key={selectedStore.storeName}
                    items={items || []}
                    onItemSelect={onItemSelect}
                />
                <div className="d-flex flex-row" style={{ marginTop: "5px", marginBottom: "5px" }}>
                    <Button className="mx-auto" onClick={clearSelection}>Close Store</Button>
                </div>
            </FSViewWrapper>
            <ContentsWrapper showCanvas={showCanvas} setShowCanvas={setShowCanvas}>
                {items !== null &&
                    <Contents
                        slicePathStack={slicePathStack}
                        pathStack={pathStack}
                        items={items}
                        onItemSelect={onItemSelect}
                    />
                }
            </ContentsWrapper>
        </>
    )
}