export const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        width: "100%",
        height: "100%",
    },
    row: {
        display: "flex",
        width: "100%",
        marginTop: "5px",
        marginBottom: "5px"
    },
    column: {
        display: "flex",
        flexDirection: "column",
    }
}
