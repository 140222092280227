import * as React from 'react';
import { SignApi } from '../service/signApi';
import { DriveApi } from '../service/driveApi';
import { FileNode, Store } from '../service/types';
// import styles from './VideoComponent.module.scss';
import { styles } from './styles';
import useBreakpoint from '../utils/useBreakpoint';

interface MediaViewerProps {
    store: Store;
    file: FileNode;
}

export const MediaViewer = (props: MediaViewerProps) => {
    const [videoUrl, setVideoUrl] = React.useState<string | null>(null);
    const [imageUrl, setImageUrl] = React.useState<string | null>(null);

    const api = React.useMemo(() => new DriveApi(), []);

    const breakpoint = useBreakpoint();

    const fetchUrl = async () => {
        if (props.file.fileType === "IMAGE") {
            const res = await api.signStoreImage(props.store.storeName, props.file.uuid);
            const imageUrl = res.url;
            setVideoUrl(null);
            setImageUrl(imageUrl);
        } else if (props.file.fileType === "VIDEO") {
            const res = await api.signStoreImage(props.store.storeName, props.file.uuid);
            const videoUrl = res.url;
            setVideoUrl(videoUrl);
            setImageUrl(null);
        }
    }

    React.useEffect(() => {
        fetchUrl()
    }, [props.file.uuid])

    return <div style={{ ...styles.container as any, alignItems: "center" }}>
        <div style={{ ...styles.row, maxWidth: "800px" }}>
            {videoUrl !== null &&
                <video
                    id="my-player"
                    className="video-js"
                    controls
                    style={{
                        maxWidth: ["xs", "sm"].includes(breakpoint) ? "100%" : "800px",
                        maxHeight: "80vh"
                    }}
                    preload="auto"
                    // poster="//vjs.zencdn.net/v/oceans.png"
                    data-setup='{}'
                >
                    <source src={videoUrl} type="video/mp4"></source>
                </video>
            }
            {imageUrl !== null &&
                <img
                    src={imageUrl}
                    alt={props.file.title}
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                        width: "auto",
                        maxHeight: "80vh",
                    }}
                />
            }
        </div>
    </div>
}