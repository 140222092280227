import React from "react";

export interface RenderFn {
    (children: React.ReactNode): React.ReactElement;
}

export const All = (renderers: RenderFn[]) =>
    (props: React.PropsWithChildren<{}>) =>
        <>
            {renderers.map(r =>
                r(props.children)
            )}
        </>