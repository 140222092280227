import axios, { Axios, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
import _ from "lodash";
import { pipe } from 'fp-ts/function';
import { getUser } from "../lib/oidc";

const emptyConfig = <D = any>(): AxiosRequestConfig<D> => ({})
const defaultApiUri = process.env.REACT_APP_API_URI || "/";

interface Endo<T> { (t: T): T }

export class BaseHTTPClient {
    private axios: Axios;
    private basepath: string;

    constructor(basepath: string = defaultApiUri) {
        this.basepath = basepath;
        this.axios = axios.create();
    }

    private setHeader<D>(
        key: string,
        val: string | number | boolean
    ): Endo<AxiosRequestConfig<D>> {
        return config => {
            const newConfig = _.cloneDeep(config);
            if (!newConfig.headers) {
                newConfig.headers = {}
            }
            _.set(newConfig.headers, key, val);
            return newConfig;
        }
    }

    private setAccept<D>(
        val: string = "application/json",
    ): Endo<AxiosRequestConfig<D>> {
        return this.setHeader("Accept", val)
    }

    private setAuthorization<D>(
        val: string,
        prefix: string | null = "Bearer",
    ): Endo<AxiosRequestConfig<D>> {
        const authStr = [prefix, val].filter(x => x).join(" ")
        return this.setHeader("Authorization", authStr)
    }

    private async fillHeaders<D>(config: AxiosRequestConfig<D>): Promise<AxiosRequestConfig<D>> {
        const user = await getUser();
        const access_token = user && user.access_token;
        const setToken = access_token
            ? this.setAuthorization<D>(access_token)
            : (<T>(x: T) => x)
        return pipe(
            config,
            this.setAccept<D>(),
            setToken
        )
    }

    protected async get<T = any, R = AxiosResponse<T, any>, D = any>(
        url: string,
        config: AxiosRequestConfig<D> = emptyConfig()
    ): Promise<R> {

        const fullConfig = await this.fillHeaders(config)
        return this.axios.get<T, R, D>(this.basepath + url, fullConfig);
    }
}