import './App.css';
import { MainLayout } from './layouts/Main';

import 'bootstrap/dist/css/bootstrap.min.css';
import { MainContainer } from './containers/Main';
import { Button } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';



function App() {
  const auth = useAuth();
  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <div className="App">
        <MainLayout>
          <MainContainer />
        </MainLayout>
      </div>
    );
  }

  return <Button onClick={() => void auth.signinRedirect()}>Log in</Button>;
}

export default App;
