import * as React from 'react';
import { Button, Col, Row, Image, Container } from 'react-bootstrap';
import { DirNode, FileNode } from '../service/types';
import { usePagination } from '../utilComponents/Pagination';
import _ from 'lodash';
import { baseName } from '../utils';

type Item = FileNode | DirNode

export interface IContentsProps {
    onItemSelect: (item: Item) => void;
    pathStack: string[];
    slicePathStack: (n: number) => void;
    items: Item[];
}

export const Contents = (props: IContentsProps) => {

    const { pages, pageNum, nextPage } = usePagination({ items: props.items, pageSize: 40 });

    const items: Item[] = React.useMemo(() => _.flatMap(pages.slice(0, pageNum), x => x) || [], [pages, pageNum]);
    const showNextButton = items.length < props.items.length;

    return (<Container fluid>
        <Col>
            <div className="d-flex flex-row">
                {_.flatMap(props.pathStack, (p, i, ar) => {
                    const isLastItem = i === ar.length - 1;
                    return [
                        <Button
                            key={`{i}th-button`}
                            disabled={isLastItem}
                            onClick={() => props.slicePathStack(i + 1)}
                        >
                            {p === "/" ? "/" : baseName(p)}
                        </Button>,
                        isLastItem ? <></> : <span className="my-auto px-2">&gt;</span>
                    ]
                })}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: 'wrap',
                    flexGrow: 1,
                }}
            >
                {items.map(item =>
                    <div className='p-2'>
                        <Image
                            key={`image-${item.uuid}`}
                            style={{
                                objectFit: 'contain',
                                width: '100%',
                                maxHeight: '150px',
                                // width: "100px",
                                // height: "100px",
                                // maxWidth: "100px",
                                // maxHeight: "100px",
                            }}
                            thumbnail
                            src={item.thumb}
                            alt={item.title}
                            onClick={() => props.onItemSelect(item)}
                        />
                    </div>
                )}
                {showNextButton &&
                    <Button style={{ maxHeight: "150px" }} onClick={() => nextPage()}>LOAD MORE</Button>
                }
            </div>
        </Col>
    </Container>)
}