import _ from 'lodash';
import React from 'react';
import { Button, CloseButton } from 'react-bootstrap';
import { Contents } from '../components/Contents';
import { FSView } from '../components/FSView';
import { MediaViewer } from '../components/MediaViewer';
import { Stores } from '../components/Stores';
import { styles } from '../components/styles';
import { DriveApi } from '../service/driveApi';
import { Store, FileNode, DirNode } from '../service/types';
import { isDir, isFile } from '../service/utils';
import { Modal } from '../utilComponents/Modal';
import { filterPredicate } from '../utils';
import { ContentsView } from '../views/ContentsView';
import { StoreSelection } from '../views/StoreSelection';
// import { Modal } from '../utilComponents/Modal';

interface IMainContainerProps { }

export const MainContainer = (props: IMainContainerProps) => {
    const driveApi = React.useMemo(() => new DriveApi(), []);

    const [selectedFile, setSelectedFile] = React.useState<FileNode | null>(null);


    //#region Stores
    const [stores, setStores] = React.useState<Store[]>([]);

    const loadStores = React.useCallback(async () => {
        const res = await driveApi.getStores();
        if (Array.isArray(res)) {
            setStores(res);
        }
    }, [driveApi]);

    React.useEffect(() => {
        loadStores()
    }, [])
    //#endregion

    //#region Path
    const [pathStack, setPathStack] = React.useState<string[]>([]);

    const topPath = _.last(pathStack) || null;
    const popPath = React.useCallback(() => {
        setPathStack(_.initial(pathStack));
    }, [pathStack]);
    const pushOnPathStack = React.useCallback((newPath: string) => {
        setPathStack([...pathStack, newPath])
    }, [pathStack]);

    //#endregion

    //#region Items
    const [selectedStore, setSelectedStore] = React.useState<Store | null>(null);
    const [items, setItems] = React.useState<(FileNode | DirNode)[] | null>(null);

    const loadFiles = React.useCallback(async (store: Store, path: string) => {
        const res = await driveApi.getContents(store.storeName, path);
        if (Array.isArray(res)) {
            const files = res.filter(isFile);
            const dirs = res.filter(isDir);
            setItems([...dirs, ...files])
        }
    }, [driveApi]);

    React.useEffect(() => {
        if (selectedStore && topPath)
            loadFiles(selectedStore, topPath);
    }, [topPath])


    React.useEffect(() => {
        if (selectedStore) {
            setPathStack(["/"]);
        } else {
            setItems(null);
            setPathStack([]);
        }
    }, [selectedStore])

    //#endregion Files

    //#region callbacks
    const onItemSelect = React.useCallback((x: (FileNode | DirNode)) => {
        if (isFile(x))
            setSelectedFile(x);
        else
            pushOnPathStack(x.fullPath);
    }, [pathStack]);

    const [prevItem, nextItem]: [FileNode | null, FileNode | null] = React.useMemo(() => {
        if (selectedFile === null || items === null) {
            return [null, null]
        } else {
            const fileItems: FileNode[] = filterPredicate(items, isFile);
            const ix = _.findIndex(fileItems, i => i.uuid === selectedFile.uuid);
            if (ix > -1) {
                const returnVals =
                    [ix - 1, ix + 1]
                        .map(n =>
                            n >= 0 && n < fileItems.length
                                ? fileItems[n]
                                : null
                        ) as [FileNode | null, FileNode | null];
                return returnVals;
            } else {
                return [null, null];
            }
        }
    }, [selectedFile, items])

    const clearSelection = React.useCallback(() => {
        setSelectedStore(null);
        setSelectedFile(null);
    }, []);

    const slicePathStack = React.useCallback((end: number) => {
        setPathStack(pathStack.slice(0, end));
    }, [pathStack])
    //#endregion

    return (
        <div style={{ ...styles.row, height: "100vh", overflow: "hidden" }}>
            {selectedStore === null &&
                <StoreSelection
                    stores={stores}
                    setSelectedStore={setSelectedStore}
                />
            }
            {selectedStore !== null &&
                <ContentsView
                    selectedStore={selectedStore}
                    clearSelection={clearSelection}
                    pathStack={pathStack}
                    popPath={popPath}
                    items={items}
                    onItemSelect={onItemSelect}
                    slicePathStack={slicePathStack}
                />
            }
            {selectedStore && selectedFile &&
                <Modal onClose={() => setSelectedFile(null)}>
                    <div
                        style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                        }}
                    >
                        <MediaViewer key={selectedFile.uuid} file={selectedFile} store={selectedStore} />
                    </div>
                    <div className="d-flex flex-row" style={{ marginBottom: "15px", justifyContent: "center" }}>
                        <Button
                            disabled={prevItem === null}
                            onClick={() => setSelectedFile(prevItem)}
                        >
                            Prev
                        </Button>
                        <Button
                            disabled={nextItem === null}
                            onClick={() => setSelectedFile(nextItem)}
                        >
                            Next
                        </Button>
                    </div>
                </Modal>
            }
        </div>
    )
}