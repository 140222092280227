import { BaseHTTPClient } from "./baseHTTPClient";
import { DirNode, FileNode, SignResponse, Store } from "./types";

export class DriveApi extends BaseHTTPClient {

    async getStores(): Promise<Store[]> {
        const res = await this.get(
            'drive/stores',
        )
        return res.data;
    }

    async getContents(store: string, path: string = "/"): Promise<(FileNode | DirNode)[]> {
        const res = await this.get(
            'drive/' + store + "?path=" + encodeURIComponent(path),
        )
        return res.data;
    }

    async signStoreImage(store: string, id: string): Promise<SignResponse> {
        const res = await this.get(
            'drive/' + store + "/sign?id=" + encodeURIComponent(id),
        )
        return res.data;
    }

}