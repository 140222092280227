const baseNameRegex = /\/([^/]+)$/;

export const baseName = (path: string) => {
    const m = baseNameRegex.exec(path);
    if (m) {
        return m[1];
    }
}

export const filterPredicate = <A, B>
(
    xs: (A | B)[],
    pred: ((x: (A | B)) => x is A)
): A[] => {
    // @ts-ignore
    return xs.filter(x => pred(x))
}