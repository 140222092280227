import { User, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";

const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    )
}

export const oidcContextExtraConf = {
    onSigninCallback,
}

export const oidcConf: UserManagerSettings = {
    // TODO: load env vars and at the start of the app and throw error if not found
    authority: process.env.REACT_APP_OAUTH2_AUTHORITY as string,
    client_id: process.env.REACT_APP_OAUTH2_CLIENT_ID as string,
    redirect_uri: process.env.REACT_APP_OAUTH2_REDIRECT_URI as string,
    scope: "openid",//"email+openid+phone",
    userStore: new WebStorageStateStore({store: window.localStorage}),

    // Config for AmazonCognito
    // no revoke of "access token" (https://github.com/authts/oidc-client-ts/issues/262)
    revokeTokenTypes: ["refresh_token"],
    // no silent renew via "prompt=none" (https://github.com/authts/oidc-client-ts/issues/366)
    automaticSilentRenew: false,
}

console.debug("Using config:\n", oidcConf)

export async function getUser() {
    const oidcStorage = localStorage.getItem(`oidc.user:${oidcConf.authority}:${oidcConf.client_id}`)
    if (!oidcStorage) {
        return null;
    }

    // TODO: should we ask for a token renewal here?
    return User.fromStorageString(oidcStorage);
}

// FIXME: don't instantiate two different UserManager for the same config
// export const userManager = new UserManager(oidcConf);
