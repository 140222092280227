import React from 'react';

interface IWithPaginationProps<T> {
    items: T[];
    className?: string;
    style?: any;
    children: (slice: T[]) => React.ReactElement[];
}

const PAGE_SIZE = 10;

interface IUsePaginationConfig<T> {
    items: T[];
    pageSize?: number;
}

export const usePagination = <T extends unknown>(config: IUsePaginationConfig<T>) => {
    const {
        items,
        pageSize = PAGE_SIZE,
    } = config;

    const pages: T[][] = React.useMemo(() => {
        const numPages = Math.ceil(items.length / pageSize);
        return [...new Array(numPages)].map((_x, i) =>
            items.slice(i * pageSize, (i + 1) * pageSize)
        )
    }, [items, pageSize]);

    const [curPage, setCurPage] = React.useState<number>(1);

    const nextPage = React.useCallback(() => setCurPage(curPage + 1), [curPage]);
    const prevPage = React.useCallback(() => setCurPage(curPage - 1), [curPage]);
    const goToPage = React.useCallback((n: number) => setCurPage(n), []);

    const page: T[] = React.useMemo(() => pages[curPage - 1] || [], [pages, curPage]);

    React.useEffect(() => { setCurPage(1) }, [pageSize, items]);

    return { pages, page, pageNum: curPage, nextPage, prevPage, goToPage };
}

export function WithPagination<T>(props: IWithPaginationProps<T>) {
    const { pages, pageNum: page, nextPage, prevPage } = usePagination({ items: props.items });

    return (
        <div
            className={props.className}
            style={props.style}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row"
                }}
            >
                <button disabled={page <= 1} onClick={() => prevPage()}>PREV</button>
                {` ${page} `}
                <button disabled={page * PAGE_SIZE >= props.items.length} onClick={() => nextPage()}>NEXT</button>
            </div>
            <div>
                {props.children(pages[page - 1] || [])}
            </div>
        </div>
    )
}