import React from 'react';
import { CloseButton } from 'react-bootstrap';

interface IModalProps extends React.PropsWithChildren<{}> {
    onClose?: () => void;
}

export const Modal = (props: IModalProps) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                zIndex: 1000,
                height: "100vh",
                width: "100vw",
                position: "fixed",
                padding: "5px 10px",
                left: 0,
                top: 0,
                backgroundColor: "#0009",
            }}
        >
            <div className="d-flex flex-row w-100">
                <CloseButton style={{ marginLeft: "auto" }} onClick={() => props.onClose && props.onClose()} />
            </div>
            <div className="d-flex flex-column h-100 w-100">
                {props.children}
            </div>
        </div>
    )
}