import * as React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Store } from '../service/types';
import { styles } from './styles'

export interface IStoresProps {
    stores: Store[];
    onSelect: (s: Store) => void;
}

export const Stores = (props: IStoresProps) => {

    return (
        <div style={styles.container as any}>
            <h2>Your Stores</h2>
            <ListGroup>
                {props.stores.map(store => (
                    <ListGroup.Item
                        key={store.storeName}
                        action
                        onClick={() => props.onSelect(store)}
                    >
                        {store.storeName}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    )
}