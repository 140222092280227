import React from 'react';

export interface IMainLayoutProps { }

export const MainLayout = (props: React.PropsWithChildren<IMainLayoutProps>) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100vw",
                height: "100vh",
                overflow: "hidden"
            }}
        >
            {props.children}
        </div>
    )
}