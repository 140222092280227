import * as React from "react";
import { Stores } from "../components/Stores";
import { styles } from "../components/styles";
import { Store } from "../service/types"

export interface IStoreSelectionProps {

    stores: Store[];
    setSelectedStore: (x: Store) => void;
}

const StoreContainer = (props: React.PropsWithChildren<{}>) => {
    return <>
        <div
            className="d-sm-block d-md-none"
            style={{ ...styles.column as any, width: "100%", overflowY: "scroll" }}
        >
            {props.children}
        </div>
        <div
            className="d-none d-md-block mx-auto"
            style={{ ...styles.column as any, maxWidth: "600px", overflowY: "scroll" }}
        >
            {props.children}
        </div>
    </>
}

export const StoreSelection = (props: IStoreSelectionProps) => {
    const { stores, setSelectedStore } = props;
    return (
        <StoreContainer>
            <Stores
                stores={stores}
                onSelect={x => setSelectedStore(x)}
            />
        </StoreContainer>
    )
}