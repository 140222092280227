import * as React from 'react';
import { WithPagination } from '../utilComponents/Pagination';
import { DriveApi } from '../service/driveApi';
import { DirNode, FileNode, Store } from '../service/types';
import { isDir, isFile } from '../service/utils';
import { styles } from './styles'
import { Container, ListGroup } from 'react-bootstrap';

export interface IPreviewProps {
    imgUrl: string;
}

export const Preview = (props: IPreviewProps) =>
    <img
        style={{ maxHeight: "200px" }}
        src={props.imgUrl}
        alt={props.imgUrl}
    ></img>

export interface IFSViewProps {
    items: (FileNode | DirNode)[];
    pathStack: string[];
    goUp: () => void;
    onItemSelect: (x: (FileNode | DirNode)) => void;
}

export const FSView = (props: IFSViewProps) => {
    return (
        <Container fluid>
            <ListGroup>
                {props.pathStack.length > 1 &&
                    <ListGroup.Item
                        action
                        onClick={() => props.goUp()}
                    >
                        ..
                    </ListGroup.Item>
                }
                {props.items
                    .map(item => isDir(item)
                        ? <ListGroup.Item
                            key={item.uuid}
                            action
                            onClick={() => props.onItemSelect(item)}
                        >
                            {item.title}
                        </ListGroup.Item>
                        : <></>
                    )}
            </ListGroup>
        </Container>
    )
}